import React from 'react';

import { Layout } from '../../components/common';
import SEO from '../../components/common/SEO';
import { useViewport } from '../../hooks';
import DesktopEventPage from '../../components/common/DesktopEventPage';
import MobileEventPage from '../../components/common/MobileEventPage';

import banner from '../../images/events/2025/cabsat/cabsat-dubai-2025-event-banner-desktop.jpg';
import mobileBanner from '../../images/events/2025/cabsat/cabsat-dubai-2025-event-banner-mobile.jpg';
import ogimage from '../../images/events/2025/cabsat/cabsat-dubai-2025-event-og.jpg';

const CabSat2025 = () => {
    const { width } = useViewport();
    const breakpoint = 500;

    return (
        <Layout title='Cabsat Dubai 2025 - Witbe' isEvents>
            <SEO
                title='Cabsat Dubai 2025 - Witbe'
                ogDescription='Meet us at Cabsat Dubai on May 13-15! This year, we will be part of the French Pavillon by Business France, Booth W2053. Discover Witbe’s Virtual NOC technology and book your live demo today.'
                description='Meet us at Cabsat Dubai on May 13-15! This year, we will be part of the French Pavillon by Business France, Booth W2053. Discover Witbe’s Virtual NOC technology and book your live demo today.'
                image={ogimage}
            />

            {width < breakpoint ? <MobileEventPage id={23} banner={mobileBanner} /> : <DesktopEventPage id={23} banner={banner} />}
        </Layout>
    );
};

export default CabSat2025;
